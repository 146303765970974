<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="การประเมิน / ตรวจสอบผลประเมินฯ ตำแหน่งครูผู้ช่วย ครั้งที่ 1 - 4"
        class="px-5 py-3"
      >
        <v-card color="basil" flat>
          <v-card-text>
            <v-card class="mb-4 pa-2" outlined>
              <v-row>
                <v-col cols="12" md="12">
                  <div>
                    <h2 class="red--text">
                      <v-icon color="red">mdi-alert</v-icon> แจ้งยกเลิกคะแนน
                    </h2>
                  </div>

                  <v-data-table
                    color="success"
                    :loading="loading"
                    :headers="headersAlert"
                    :items="assistant_teachers"
                    :search="searchCC"
                    :item-class="row_classes"
                    :items-per-page="5"
                  >
                    <template v-slot:top>
                      <div>
                        <v-text-field
                          v-model="searchCC"
                          append-icon="mdi-magnify"
                          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                          filled
                        />
                      </div>
                    </template>
                    <template v-slot:item.assistant_teacher_comment="{ item }">
                      <div
                        v-if="item.assistant_teacher_comment === 'collegeCC'"
                      >
                        <v-btn
                          @click="cancelAssistant(item.assistant_teacher_id)"
                          rounded
                          color="error"
                          ><v-icon>mdi-alert</v-icon> ยกเลิกไฟล์</v-btn
                        >
                        <div>
                          {{ item.assistant_teacher_comment_detail }}
                        </div>
                      </div>
                    </template>

                    <template v-slot:item.mt_date_app_now="{ item }">
                      <div>
                        {{
                          item.mt_date_app_now
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>
                    </template>

                    <template v-slot:item.assistant_teacher_datetime="{ item }">
                      <div>
                        {{
                          item.assistant_teacher_datetime
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>
                    </template>

                    <template v-slot:item.assistant_teacher_score="{ item }">
                      <v-chip
                        v-if="
                          item.assistant_teacher_score < 50 &&
                            item.assistant_teacher_times <= 2
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip
                        v-if="
                          item.assistant_teacher_score < 60 &&
                            item.assistant_teacher_times === '3'
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip
                        v-if="
                          item.assistant_teacher_score < 60 &&
                            item.assistant_teacher_times === '4'
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip v-else color="green" dark>
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                    </template>

                    <template v-slot:item.assistant_teacher_status="{ item }">
                      <v-chip
                        v-if="item.assistant_teacher_status === 'confirm'"
                        color="green"
                        dark
                      >
                        <v-icon> mdi-cube-send</v-icon> ส่งผลการประเมิน
                      </v-chip>

                      <v-chip
                        v-else
                        color="info"
                        @click="viewFile(item.assistant_teacher_file)"
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-chip>
                    </template>

                    <template v-slot:item.assistant_teacher_ipacheck="{ item }">
                      <div>
                        <v-btn
                          v-if="item.assistant_teacher_ipacheck === 'Pass'"
                          fab
                          small
                          color="success"
                          @click="checkAssistant(item.assistant_teacher_id)"
                          ><v-icon>mdi-account-check</v-icon>
                        </v-btn>

                        <v-btn
                          v-else-if="
                            item.assistant_teacher_ipacheck === 'NotPass'
                          "
                          fab
                          small
                          color="red"
                          @click="checkAssistant(item.assistant_teacher_id)"
                          ><v-icon>mdi-account-remove</v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          fab
                          small
                          color="warning"
                          @click="checkAssistant(item.assistant_teacher_id)"
                          ><v-icon>mdi-account-edit</v-icon>
                        </v-btn>
                      </div>
                    </template>

                    <v-alert
                      slot="no-results"
                      :value="true"
                      color="error"
                      icon="mdi-alert"
                      >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
                    >
                  </v-data-table>
                </v-col>

                <v-col cols="12" md="12">
                  <div>
                    <h2 class="warning--text">
                      <v-icon color="warning">mdi-alert</v-icon> รายการยกเลิก
                    </h2>
                  </div>

                  <v-data-table
                    color="success"
                    :loading="loading"
                    :headers="headersAlert"
                    :items="assistant_teachersCC"
                    :search="searchCC"
                    :item-class="row_classes"
                    :items-per-page="5"
                  >
                    <template v-slot:top>
                      <div>
                        <v-text-field
                          v-model="searchCC"
                          append-icon="mdi-magnify"
                          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                          filled
                        />
                      </div>
                    </template>

                    <template v-slot:item.mt_date_app_now="{ item }">
                      <div>
                        {{
                          item.mt_date_app_now
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>
                    </template>

                    <template v-slot:item.assistant_teacher_datetime="{ item }">
                      <div>
                        {{
                          item.assistant_teacher_datetime
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>
                    </template>

                    <template v-slot:item.assistant_teacher_score="{ item }">
                      <v-chip
                        v-if="
                          item.assistant_teacher_score < 50 &&
                            item.assistant_teacher_times <= 2
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip
                        v-if="
                          item.assistant_teacher_score < 60 &&
                            item.assistant_teacher_times === '3'
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip
                        v-if="
                          item.assistant_teacher_score < 60 &&
                            item.assistant_teacher_times === '4'
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip v-else color="green" dark>
                        <h2>{{ item.assistant_teacher_score }}</h2>
                      </v-chip>
                    </template>

                    <template v-slot:item.assistant_teacher_status="{ item }">
                      <v-chip
                        v-if="item.assistant_teacher_status === 'confirm'"
                        color="green"
                        dark
                      >
                        <v-icon> mdi-cube-send</v-icon> ส่งผลการประเมิน
                      </v-chip>

                      <v-chip
                        v-else
                        color="info"
                        @click="viewFile(item.assistant_teacher_file)"
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-chip>
                    </template>

                    <template v-slot:item.assistant_teacher_ipacheck="{ item }">
                      <div>
                        <v-btn
                          v-if="item.assistant_teacher_ipacheck === 'Pass'"
                          fab
                          small
                          color="success"
                          @click="checkAssistant(item.assistant_teacher_id)"
                          ><v-icon>mdi-account-check</v-icon>
                        </v-btn>

                        <v-btn
                          v-else-if="
                            item.assistant_teacher_ipacheck === 'NotPass'
                          "
                          fab
                          small
                          color="red"
                          @click="checkAssistant(item.assistant_teacher_id)"
                          ><v-icon>mdi-account-remove</v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          fab
                          small
                          color="warning"
                          @click="checkAssistant(item.assistant_teacher_id)"
                          ><v-icon>mdi-account-edit</v-icon>
                        </v-btn>
                      </div>
                    </template>

                    <v-alert
                      slot="no-results"
                      :value="true"
                      color="error"
                      icon="mdi-alert"
                      >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
                    >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>

        <v-card color="basil" flat>
          <v-card-text>
            <v-card class="mb-4 pa-2" outlined>
              <div class="">
                <h2 class="success--text">
                  <v-icon color="success">mdi-check-decagram</v-icon>
                  ตรวจสอบผลประเมินฯ
                </h2>
              </div>
              <v-row no-gutters>
                <v-col cols="12" md="4" class="pa-1">
                  <v-autocomplete
                    outlined
                    label="ค้นหา วิทยาลัย :"
                    :items="colleges"
                    item-text="college_name"
                    item-value="college_code"
                    v-model="college_select"
                    @change="personnel_temporaryCollegeQueryAll()"
                  ></v-autocomplete>

                  <div>
                    <v-text-field
                      outlined
                      v-model="updateorder_app_now.order_app_now"
                      label="ปรับปรุงคำสั่งที่บรรจุ คำสั่งที่ :"
                    ></v-text-field>
                  </div>
                </v-col>

                <v-col cols="12" md="8" class="pa-1">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td class="text-center" colspan="2">
                          <v-btn
                            :href="
                              '#/admin/print_AssismentReportCPDay2/?appoin_day=' +
                                appoin_day +
                                '&appoin_month=' +
                                appoin_month +
                                '&appoin_year=' +
                                appoin_year
                            "
                            target="_blank"
                            color="info"
                            class="mr-2"
                            v-if="check_param_query > 1"
                            ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน</v-btn
                          >
                        </td>
                        <td class="text-center" colspan="2">
                          <v-btn
                            class="mr-2"
                            @click="UpdateSystem()"
                            color="success"
                            ><v-icon>mdi-update</v-icon>Update
                          </v-btn>
                        </td>
                        <td class="text-center" colspan="2">
                          <v-btn
                            class="mr-2"
                            outlined
                            @click="checkappoint()"
                            color="info"
                            ><v-icon>mdi-magnify</v-icon>Show All
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center" colspan="3">
                          <v-btn
                            outlined
                            @click="assistant_teacherQueryAll()"
                            color="warning"
                            ><v-icon>mdi-magnify</v-icon>คะแนนต่ำกว่าเกณฑ์
                          </v-btn>
                        </td>
                        <td class="text-center" colspan="3">
                          <v-btn
                            outlined
                            @click="assistant_teacher_update()"
                            color="success"
                            ><v-icon>mdi-magnify</v-icon>ปรับปรุงคะแนนประเมิน
                          </v-btn>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="6" class="text-center">
                          <h3>
                            จำนวนทั้งสิ้น {{ checkdatareport.countall }} อัตรา
                            ดำเนินการตรวจสอบแล้ว
                          </h3>
                        </td>
                      </tr>

                      <tr style="background-color: #8DBB64FF;">
                        <td class="text-center font-weight-bold">
                          ครั้งที่
                        </td>

                        <td class="text-center font-weight-bold">
                          ให้ผ่าน
                        </td>

                        <td class="text-center font-weight-bold">
                          ไม่ให้ผ่าน
                        </td>
                        <td class="text-center font-weight-bold">
                          คะแนนต่ำกว่าเกณฑ์
                        </td>
                        <td class="text-center font-weight-bold">
                          ไม่ได้ดำเนินการ
                        </td>
                        <td class="text-center font-weight-bold">
                          ไม่รายงาน
                        </td>
                      </tr>

                      <tr>
                        <td class="text-center font-weight-bold">
                          ครั้งที่ 1
                        </td>
                        <td class="text-center success--text">
                          <v-icon color="success">mdi-check-circle</v-icon> ผ่าน
                          {{ checkdatareport.counttime1pass }}
                        </td>
                        <td class="text-center red--text">
                          <v-icon color="error">mdi-close</v-icon> ไม่ผ่าน
                          {{ checkdatareport.counttime1notpass }}
                        </td>
                        <td class="text-center error--text">
                          <div v-if="timelowscore.counttime1lowscore > 0">
                            <v-icon color="error">mdi-alert</v-icon>
                            {{ timelowscore.counttime1lowscore }}
                          </div>
                        </td>
                        <td class="text-center warning--text">
                          <v-icon color="warning">mdi-alert</v-icon> ไม่ได้ตรวจ
                          {{ checkdatareport.counttime1notcheck }}
                        </td>
                        <td class="text-center error--text">
                          <v-icon color="error">mdi-alert</v-icon> ไม่รายงาน
                          {{ checkdatareport.counttime1null }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center font-weight-bold">
                          ครั้งที่ 2
                        </td>
                        <td class="text-center success--text">
                          <v-icon color="success">mdi-check-circle</v-icon> ผ่าน
                          {{ checkdatareport.counttime2pass }}
                        </td>
                        <td class="text-center red--text">
                          <v-icon color="error">mdi-close</v-icon> ไม่ผ่าน
                          {{ checkdatareport.counttime2notpass }}
                        </td>
                        <td class="text-center red--text">
                          <div v-if="timelowscore.counttime2lowscore > 0">
                            <v-icon color="error">mdi-alert</v-icon>
                            {{ timelowscore.counttime2lowscore }}
                          </div>
                        </td>
                        <td class="text-center warning--text">
                          <v-icon color="warning">mdi-alert</v-icon> ไม่ได้ตรวจ
                          {{ checkdatareport.counttime2notcheck }}
                        </td>
                        <td class="text-center error--text">
                          <v-icon color="error">mdi-alert</v-icon> ไม่รายงาน
                          {{ checkdatareport.counttime2null }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center font-weight-bold">
                          ครั้งที่ 3
                        </td>
                        <td class="text-center success--text">
                          <v-icon color="success">mdi-check-circle</v-icon> ผ่าน
                          {{ checkdatareport.counttime3pass }}
                        </td>
                        <td class="text-center red--text">
                          <v-icon color="error">mdi-close</v-icon> ไม่ผ่าน
                          {{ checkdatareport.counttime3notpass }}
                        </td>
                        <td class="text-center warning--text">
                          <div v-if="timelowscore.counttime3lowscore > 0">
                            <v-icon color="error">mdi-alert</v-icon>
                            {{ timelowscore.counttime3lowscore }}
                          </div>
                        </td>
                        <td class="text-center warning--text">
                          <v-icon color="warning">mdi-alert</v-icon> ไม่ได้ตรวจ
                          {{ checkdatareport.counttime3notcheck }}
                        </td>
                        <td class="text-center error--text">
                          <v-icon color="error">mdi-alert</v-icon> ไม่รายงาน
                          {{ checkdatareport.counttime3null }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center font-weight-bold">
                          ครั้งที่ 4
                        </td>
                        <td class="text-center success--text">
                          <v-icon color="success">mdi-check-circle</v-icon> ผ่าน
                          {{ checkdatareport.counttime4pass }}
                        </td>
                        <td class="text-center red--text">
                          <v-icon color="error">mdi-close</v-icon> ไม่ผ่าน
                          {{ checkdatareport.counttime4notpass }}
                        </td>
                        <td class="text-center warning--text">
                          <div v-if="timelowscore.counttime4lowscore > 0">
                            <v-icon color="error">mdi-alert</v-icon>
                            {{ timelowscore.counttime4lowscore }}
                          </div>
                        </td>

                        <td class="text-center warning--text">
                          <v-icon color="warning">mdi-alert</v-icon> ไม่ได้ตรวจ
                          {{ checkdatareport.counttime4notcheck }}
                        </td>

                        <td class="text-center error--text">
                          <v-icon color="error">mdi-alert</v-icon> ไม่รายงาน
                          {{ checkdatareport.counttime4null }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>

                <v-col cols="12" md="6" class="text-center">
                  <v-btn
                    v-if="updateorder_app_now.order_app_now"
                    @click="updateOrder()"
                    color="success"
                  >
                    <v-icon>mdi-update</v-icon> Update</v-btn
                  >
                </v-col>

                <!--   <v-col cols="12" md="6">
                      <v-radio-group row>
                         <v-radio
                          label="ไม่ได้เริ่มประเมิน"
                          color="red"
                          value="not_confirm"
                          @click="assistant_teacher_not_evalution()"
                        ></v-radio>
                        <v-radio
                          label="ประเมินเสร็จสิ้น"
                          color="green"
                          value="confirm"
                          @click="assistant_teacher_success()"
                        ></v-radio> 
                        <v-radio
                          label="แสดงทั้งหมด"
                          color="info"
                          value="show"
                          @click="checkappoint()"
                        ></v-radio>               
                      </v-radio-group>
                    </v-col>-->
              </v-row>
            </v-card>
            <v-data-table
            v-if="loading===false"
              :headers="showHeaders"
              :items="personnel_temporarys"
              class="elevation-1"
              :loading="loading"
              :search="search"
            >
              <template v-slot:top>
                <div>
                  <v-text-field
                    @keyup="checkmanage_assistant_teacherid"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง หรือค้นหาด้วยรหัสบัตรประชาชน และ กด Enter"
                    filled
                  />
                </div>
             <!--    <div>
                  <v-select
                    v-model="selectedHeaders"
                    :items="headers"
                    label="เลือกคอลัมน์ที่ต้องการแสดง"
                    multiple
                    outlined
                    return-object
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 6">
                        <span>{{ item.text }}</span>
                      </v-chip>
                      <span v-if="index === 6" class="grey--text caption"
                        >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                      >
                    </template>
                  </v-select>
                </div> -->
              </template>

              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="text-center">
                    {{ index + 1 }}
                  </td>

                  <td class="text-center">
                    {{ item.user_code }}
                  </td>
                  <td class="text-center">
                    {{ item.agency }}
                  </td>

                  <td class="text-center">
                    {{ item.province_name }}
                  </td>

                  <td class="text-center">
                    {{ item.region_name }}
                  </td>
                  <td class="text-center">
                    {{ item.mt_id_card }}
                  </td>
                  <td class="text-center">
                    {{ item.mt_title_s }}
                  </td>
                  <td class="text-center">
                    {{ item.mt_frist_name }}
                  </td>
                  <td class="text-center">
                    {{ item.mt_last_name }}
                  </td>
                  <td class="text-center">
                    {{ item.mt_id_position }}
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_license_status === 'have'">
                      <v-icon color="info" size="30"
                        >mdi-card-account-details-star</v-icon
                      >
                    </div>

                    <div v-if="item.mt_license_status === 'donot'">
                      <v-icon color="warning" size="30"
                        >mdi-card-bulleted-off</v-icon
                      >

                      <div v-if="item.pt_licence_tem">
                        <div
                          v-if="String(item.pt_licence_file_tem).length < 10"
                        >
                          <v-chip color="error" outlined>
                            <v-icon>mdi-alert</v-icon> ไม่ได้แนบไฟล์
                          </v-chip>
                        </div>
                        <div v-else>
                          <v-chip
                            @click="
                              viewpdflicenseteach(item.pt_licence_file_tem)
                            "
                            outlined
                            color="info"
                          >
                            <v-icon>mdi-magnify</v-icon>
                            {{ item.pt_licence_tem }}</v-chip
                          >
                        </div>
                        หมดอายุวันที่ <br />
                        {{
                          item.pt_enddate_tem
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                        <div
                          v-if="item.datediffendtem <= 0"
                          class="red--text font-weight-bold"
                        >
                          <v-icon color="red" size="30">mdi-alert</v-icon>
                          <br />
                          หมดก่อน {{ item.datediffendtem }} วัน
                        </div>

                        <v-btn
                          icon
                          @click="updateLicense(item.mt_id)"
                          color="grey"
                          dark
                        >
                          <v-icon>mdi-magnify-close</v-icon>
                        </v-btn>
                      </div>
                    </div>

                    <div v-if="item.pt_licence_file_ipa === 'pass'">
                      <v-icon color="success">mdi-check-circle</v-icon>
                    </div>

                    <div
                      v-if="item.pt_licence_file_ipa === 'dontpass'"
                      class="red--text"
                    >
                      {{ item.pt_licence_file_ipacomment }}
                    </div>

                    <div v-if="item.pt_licence">
                      <div v-if="String(item.pt_licence_file).length < 10">
                        <v-chip color="error" outlined>
                          <v-icon>mdi-alert</v-icon> ไม่ได้แนบไฟล์
                        </v-chip>
                      </div>
                      <div v-else>
                        <v-chip
                          @click="viewpdflicenseteach(item.pt_licence_file)"
                          outlined
                          color="info"
                        >
                          <v-icon>mdi-magnify</v-icon>
                          {{ item.pt_licence }}</v-chip
                        >
                      </div>
                      หมดอายุวันที่ <br />
                      {{
                        item.pt_enddate
                          | moment("add", "543 year")
                          | moment("D MMMM YYYY")
                      }}
                      <div
                        v-if="item.datediffend <= 0"
                        class="red--text font-weight-bold"
                      >
                        <v-icon color="red" size="30">mdi-alert</v-icon> <br />
                        หมดก่อน {{ item.datediffend }} วัน
                      </div>

                      <br />
                      <v-btn
                        icon
                        @click="updateLicense(item.mt_id)"
                        color="grey"
                        dark
                      >
                        <v-icon>mdi-magnify-close</v-icon>
                      </v-btn>
                    </div>
                  </td>

                  <td class="text-center">
                    <div
                      v-if="item.mt_committe_ipaapprove === 'dontpass'"
                      class="red--text"
                    >
                      {{ item.mt_committe_ipaapprove_detail }}
                    </div>
                    <div v-if="item.mt_committe_ipaapprove === 'pass'">
                      <v-icon color="success">mdi-check-circle</v-icon>
                    </div>
                    <div v-if="item.mt_committe_file">
                      <div>
                        <v-btn
                          color="info"
                          icon
                          @click="viewFile(item.mt_committe_file)"
                          ><v-icon>mdi-printer</v-icon></v-btn
                        >
                      </div>
                      <div v-if="item.mt_committe_ipaapprove === 'pass'">
                        <v-icon color="success">mdi-check-circle</v-icon>
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.pertem_assistantFile_ipacheck === 'pass'">
                      <v-icon color="success">mdi-check-circle</v-icon>
                    </div>
                    <div
                      v-else-if="
                        item.pertem_assistantFile_ipacheck === 'dontpass'
                      "
                      class="red--text"
                    >
                      {{ item.pertem_assistantFile_ipacheck_detail }}
                    </div>

                    <div v-if="item.pertem_assistantFile">
                      <div>
                        <v-btn
                          color="info"
                          icon
                          @click="viewFile(item.pertem_assistantFile)"
                          ><v-icon>mdi-printer</v-icon></v-btn
                        >

                        <v-btn
                          icon
                          @click="updateAssisstant(item.mt_id)"
                          color="grey"
                          dark
                        >
                          <v-icon>mdi-magnify-close</v-icon>
                        </v-btn>
                      </div>
                      <div v-if="item.pertem_assistantFileCC === 'CC'">
                        <v-chip dark color="red">คำขอยกเลิกแนบไฟล์</v-chip>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="item.mt_time_1_score > 0">
                      <div v-if="item.mt_time_1_score_a < 24" class="red--text">
                        {{ item.mt_time_1_score_a }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_1_score_a }}
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_time_1_score > 0">
                      <div v-if="item.mt_time_1_score_b < 36" class="red--text">
                        {{ item.mt_time_1_score_b }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_1_score_b }}
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div>
                      <span v-if="item.mt_time_1_score > 0">
                        {{ item.mt_time_1_score }}
                      </span>
                      <div v-if="String(item.mt_time_1_file).length > 4">
                        <v-btn
                          @click="viewFile(item.mt_time_1_file)"
                          color="info"
                          small
                          icon
                          ><v-icon>mdi-printer</v-icon></v-btn
                        >
                      </div>
                      <div v-if="item.mt_time_1_score > 0">
                        <div v-if="item.mt_time_1_ck === 'Pass'">
                          <v-btn
                            color="success"
                            @click="checkAssistant(item.mt_time_1_id)"
                            icon
                            small
                          >
                            <v-icon>mdi-check-circle</v-icon>
                          </v-btn>
                        </div>

                        <div v-else-if="item.mt_time_1_ck === 'NotPass'">
                          <v-btn
                            color="red"
                            @click="checkAssistant(item.mt_time_1_id)"
                            icon
                            small
                          >
                            <v-icon>mdi-close-box</v-icon>
                          </v-btn>
                        </div>

                        <div v-else>
                          <v-btn
                            @click="checkAssistant(item.mt_time_1_id)"
                            icon
                            small
                          >
                            <v-icon>mdi-magnify-close</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_time_2_score > 0">
                      <div v-if="item.mt_time_2_score_a < 24" class="red--text">
                        {{ item.mt_time_2_score_a }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_2_score_a }}
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_time_2_score > 0">
                      <div v-if="item.mt_time_2_score_b < 36" class="red--text">
                        {{ item.mt_time_2_score_b }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_2_score_b }}
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div>
                      <span v-if="item.mt_time_2_score > 0">
                        {{ item.mt_time_2_score }}
                      </span>
                      <div v-if="String(item.mt_time_2_file).length > 4">
                        <v-btn
                          @click="viewFile(item.mt_time_2_file)"
                          color="info"
                          small
                          icon
                          ><v-icon>mdi-printer</v-icon></v-btn
                        >
                      </div>
                      <div v-if="item.mt_time_2_score > 0">
                        <v-btn
                          v-if="item.mt_time_2_ck === 'Pass'"
                          color="success"
                          @click="checkAssistant(item.mt_time_2_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-check-circle</v-icon>
                        </v-btn>
                        <v-btn
                          v-else-if="item.mt_time_2_ck === 'NotPass'"
                          color="red"
                          @click="checkAssistant(item.mt_time_2_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-close-box</v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          @click="checkAssistant(item.mt_time_2_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-magnify-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_time_3_score > 0">
                      <div v-if="item.mt_time_3_score_a < 28" class="red--text">
                        {{ item.mt_time_3_score_a }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_3_score_a }}
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_time_3_score > 0">
                      <div v-if="item.mt_time_3_score_b < 42" class="red--text">
                        {{ item.mt_time_3_score_b }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_3_score_b }}
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div>
                      <span v-if="item.mt_time_3_score > 0">
                        {{ item.mt_time_3_score }}
                      </span>
                      <div v-if="String(item.mt_time_3_file).length > 4">
                        <v-btn
                          @click="viewFile(item.mt_time_3_file)"
                          color="info"
                          small
                          icon
                          ><v-icon>mdi-printer</v-icon></v-btn
                        >
                      </div>
                      <div v-if="item.mt_time_3_score > 0">
                        <v-btn
                          v-if="item.mt_time_3_ck === 'Pass'"
                          color="success"
                          @click="checkAssistant(item.mt_time_3_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-check-circle</v-icon>
                        </v-btn>

                        <v-btn
                          v-else-if="item.mt_time_3_ck === 'NotPass'"
                          color="red"
                          @click="checkAssistant(item.mt_time_3_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-close-box</v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          @click="checkAssistant(item.mt_time_3_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-magnify-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_time_4_score > 0">
                      <div v-if="item.mt_time_4_score_a < 28" class="red--text">
                        {{ item.mt_time_4_score_a }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_4_score_a }}
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.mt_time_4_score > 0">
                      <div v-if="item.mt_time_4_score_b < 42" class="red--text">
                        {{ item.mt_time_4_score_b }}
                      </div>
                      <div v-else class="green--text">
                        {{ item.mt_time_4_score_b }}
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div>
                      <span v-if="item.mt_time_4_score > 0">
                        {{ item.mt_time_4_score }}
                      </span>
                      <div v-if="String(item.mt_time_4_file).length > 4">
                        <v-btn
                          @click="viewFile(item.mt_time_4_file)"
                          color="info"
                          small
                          icon
                          ><v-icon>mdi-printer</v-icon></v-btn
                        >
                      </div>
                      <div v-if="item.mt_time_4_score > 0">
                        <v-btn
                          v-if="item.mt_time_4_ck === 'Pass'"
                          color="success"
                          @click="checkAssistant(item.mt_time_4_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-check-circle</v-icon>
                        </v-btn>

                        <v-btn
                          v-else-if="item.mt_time_4_ck === 'NotPass'"
                          color="red"
                          @click="checkAssistant(item.mt_time_4_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-close-box</v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          @click="checkAssistant(item.mt_time_4_id)"
                          icon
                          small
                        >
                          <v-icon>mdi-magnify-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </td>

                  <td class="text-center">
                    <div>
                      {{
                        item.mt_date_app_now
                          | moment("add", "543 year")
                          | moment("D MMMM YYYY")
                      }}
                    </div>
                  </td>
                  <!--   <td class="text-center">
                    <v-icon
                      color="yellow"
                      @click.stop="personnel_temporaryEdit(item.mt_id_card)"
                    >
                      mdi-pencil
                    </v-icon>
                  </td> -->
                </tr>
              </template>

              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </base-material-card>

      <!-- V-model evaluate_assistantdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="evaluate_assistantdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนการประเมิน รายบุคคล"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="evaluate_assistantform" lazy-validation>
                <v-container grid-list-md>
                  <v-card>
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>

                    <v-card-title
                      ><v-icon large color="info">mdi-account-check</v-icon>
                      <h2>ข้อมูลครูผู้ช่วย ผู้รับการประเมิน</h2></v-card-title
                    >
                    <v-card-text>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ personnel_temporary_mt_id_card.college_name }}
                        </h3>
                        รหัสบัตรประชาชน :
                        {{ personnel_temporary_mt_id_card.mt_id_card }} <br />
                        ชื่อ-นามสกุล :
                        {{ personnel_temporary_mt_id_card.mt_frist_names }}
                        <br />
                        วันที่บรรจุ :
                        {{ personnel_temporary_mt_id_card.date_app }} <br />
                        คำสั่งที่บรรจุ :
                        {{ personnel_temporary_mt_id_card.order_app_now }}
                        <h3>ครั้งที่ประเมิน : {{ times_s }}</h3>
                      </div>
                      <div class="my-4"></div>
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-title class="text-center">
                      <h3>ส่งผลการประเมิน</h3>
                    </v-card-title>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          v-if="times_s !== 'ครบการประเมิน'"
                          color="success"
                          elevation="4"
                          large
                          rounded
                          @click="assistant_teacher_add()"
                        >
                          <v-icon>mdi-content-save</v-icon>
                          <span>กรอกผลการประเมิน</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-data-table
                          :headers="headers_aassistant_teacher"
                          :items="assistant_teachers"
                          class="elevation-1"
                          :loading="loading"
                        >
                          |<!--  moment("add","543 years") | moment("D MMMM YYYY") -->

                          <template
                            v-slot:item.assistant_teacher_datetime="{
                              item
                            }"
                          >
                            {{
                              item.assistant_teacher_datetime
                                | moment("add", "543 years")
                                | moment("D MMMM YYYY")
                            }}
                          </template>

                          <template
                            v-slot:item.assistant_teacher_status="{ item }"
                          >
                            <v-chip
                              v-if="item.assistant_teacher_status === 'confirm'"
                              color="green"
                              dark
                            >
                              <h2>ส่งผลการประเมินเรียบร้อย</h2>
                            </v-chip>
                            <v-chip v-else color="warning" dark>
                              <h2>อยู่ระหว่างการประเมินผล</h2>
                            </v-chip>
                          </template>

                          <template
                            v-slot:item.assistant_teacher_comment="{ item }"
                          >
                            <v-icon
                              v-if="item.assistant_teacher_status === 'confirm'"
                              color="green"
                              large
                            >
                              mdi-cube-send
                            </v-icon>
                            <v-icon
                              v-else
                              large
                              color="yellow"
                              @click.stop="
                                assistant_teacherEdit(item.assistant_teacher_id)
                              "
                            >
                              mdi-pencil
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-chip
                            dark
                            color="red"
                            v-if="
                              times_s === 'ครบการประเมิน' &&
                                result_assistant_teacher_reports.assistant_teacher_re_mt_id_card !==
                                  personnel_temporary_mt_id_card.mt_id_card
                            "
                          >
                            <h2>
                              <v-icon>mdi-alert</v-icon>
                              เมื่อกดรายงานผลการประเมินและยืนยันผลการประเมิน
                              สถานศึกษาจะไม่สามารถดำเนินการแก้ไขข้อมูลได้แล้ว
                              กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน
                            </h2>
                          </v-chip>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <v-btn
                            @click="cancel_assistant_teacher_report()"
                            color="red"
                            large
                            rounded
                            v-if="
                              result_assistant_teacher_reports.assistant_teacher_re_mt_id_card ===
                                personnel_temporary_mt_id_card.mt_id_card
                            "
                          >
                            <v-icon>mdi-alert</v-icon>
                            &nbsp; ยกเลิกรายการ
                          </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <v-btn
                            large
                            @click.stop="evaluate_assistantdialog = false"
                            rounded
                          >
                            <v-icon dark>mdi-close</v-icon>ยกเลิก
                          </v-btn>

                          <v-btn
                            v-if="
                              times_s === 'ครบการประเมิน' &&
                                result_assistant_teacher_reports.assistant_teacher_re_mt_id_card !==
                                  personnel_temporary_mt_id_card.mt_id_card
                            "
                            large
                            color="green"
                            @click.stop="evaluate_assistantSubmit()"
                            rounded
                          >
                            <v-icon dark>mdi-content-save</v-icon
                            >&nbsp;รายงานผลการประเมิน
                          </v-btn>
                          <v-btn
                            color="info"
                            large
                            rounded
                            v-if="
                              result_assistant_teacher_reports.assistant_teacher_re_mt_id_card ===
                                personnel_temporary_mt_id_card.mt_id_card
                            "
                          >
                            <v-icon>mdi-printer</v-icon>
                            &nbsp; พิมพ์รายงาน
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="assistant_teacherdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_mt_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_mt_id_card.mt_id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_mt_id_card.mt_frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.order_app_now }} <br />
                      <br />

                      <h3>ครั้งที่ประเมิน : {{ times_s }}</h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="addassistant_teacher.assistant_teacher_score"
                        type="number"
                        label="คะแนน"
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              addassistant_teacher.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            addassistant_teacher.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ปีที่ 1 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ปีที่ 2 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherEditdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherEditdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherEditform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_mt_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_mt_id_card.mt_id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_mt_id_card.mt_frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.order_app_now }} <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="
                          assistant_teachers_edit.assistant_teacher_score
                        "
                        type="number"
                        label="คะแนน"
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              assistant_teachers_edit.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            assistant_teachers_edit.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu1 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu1.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ปีที่ 1 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ปีที่ 2 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherEditdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherEditdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherConfirmdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherConfirmdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยืนยันผลการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherConfirmdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_mt_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_mt_id_card.mt_id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_mt_id_card.mt_frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.order_app_now }} <br />
                      <h3>
                        คะแนนเฉลี่ย :
                        {{ personnel_temporary_mt_id_card.avg_score }}
                      </h3>
                      <br />

                      <h3>
                        หากกดปุ่มยืนยันแล้วจะไม่สามารถดำเนินการแก้ไขได้
                        กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="assistant_teacherConfirmdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="assistant_teacherConfirmdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยันผลการประเมิน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model cancel_assistant_teacher_reportdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="cancel_assistant_teacher_reportdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการประเมินผล"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="cancel_assistant_teacher_reportdialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ personnel_temporary_mt_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ personnel_temporary_mt_id_card.mt_id_card }} <br />
                      ชื่อ-นามสกุล :
                      {{ personnel_temporary_mt_id_card.mt_frist_names }} <br />
                      วันที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ personnel_temporary_mt_id_card.order_app_now }} <br />
                      <h3>
                        คะแนนเฉลี่ย :
                        {{ personnel_temporary_mt_id_card.avg_score }}
                      </h3>
                      <br />
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="cancel_assistant_teacher_reportdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="cancel_assistant_teacher_reportdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon
                >&nbsp;ยืนยันการยกเลิกผลการประเมิน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pertem_assistantFileCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="pertem_assistantFileCCdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ยกเลิกแนบคำสั่งแต่งตั้งกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="pertem_assistantFileCCform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ editpersonnel_temporary.college_name }}
                        </h3>
                        รหัสบัตรประชาชน :
                        {{ editpersonnel_temporary.id_card }} <br />
                        ชื่อ-นามสกุล :
                        {{ editpersonnel_temporary.frist_names }} <br />
                        วันที่บรรจุ :
                        {{ editpersonnel_temporary.date_app }} <br />
                        คำสั่งที่บรรจุ :
                        {{ editpersonnel_temporary.order_app_now }}
                      </div>
                    </v-flex>

                    <v-flex md12>
                      <v-radio-group
                        v-model="editpersonnel_temporary.mt_committe_ipaapprove"
                        row
                      >
                        <template v-slot:label>
                          <div><strong>สถานะการตรวจสอบ</strong></div>
                        </template>
                        <v-radio value="pass">
                          <template v-slot:label>
                            <div>
                              <strong class="success--text"
                                >ถูกต้อง/ผ่าน</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="dontpass">
                          <template v-slot:label>
                            <div>
                              <strong class="error--text"
                                >ไม่ถูกต้อง/ไม่ผ่าน</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>

                    <v-flex
                      md12
                      v-if="
                        editpersonnel_temporary.mt_committe_ipaapprove ===
                          'dontpass'
                      "
                    >
                      <v-textarea
                        outlined
                        label="เหตุผล"
                        v-model="
                          editpersonnel_temporary.mt_committe_ipaapprove_detail
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green"
                @click.stop="pertem_assistantFileCCSubmit()"
                rounded
              >
                <v-icon dark>mdi-checkbox-marked-circle</v-icon
                >&nbsp;ยืนยันยกเลิก
              </v-btn>
              <v-btn
                outlined
                color="warning"
                @click.stop="pertem_assistantFileCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editpersonnel_temporarydialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporarydialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ปรับปรุงข้อมูลบุคลากร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporaryform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <h3>ข้อมูลการเริ่มปฏิบัติหน้าที่ในสถานศึกษาปัจจุบัน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="คำสั่งที่ ระบุเพียง : 783/2563"
                        v-model="editpersonnel_temporary.order_app_now"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editpersonnel_temporary.date_app_now"
                            label="วันเดือนเริ่มปฏิบัติงาน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editpersonnel_temporary.date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu3 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu3.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="editpersonnel_temporarydialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="editpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog v-model="pdflicenseteachdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecLicenseTeach/' + pt_licence_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog v-model="assistant_teacher_filedialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + filesAss"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporaryCheckdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="การตรวจสอบข้อมูล ผลการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporaryCheckform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div
                        v-if="
                          String(assistant_teachers_edit.assistant_teacher_file)
                            .length > 4
                        "
                        class="text-center"
                      >
                        <v-btn
                          @click="
                            viewFile(
                              assistant_teachers_edit.assistant_teacher_file
                            )
                          "
                          color="info"
                          icon
                          ><v-icon size="50">mdi-printer</v-icon></v-btn
                        >
                      </div>
                      {{ assistant_teachers_edit.user_firstname }}
                      <br />
                      ชื่อ-นามสกุล :
                      <span class="font-weight-bold"
                        >{{ assistant_teachers_edit.frist_names }}
                      </span>
                      <br />
                      ครั้งที่ :
                      {{ assistant_teachers_edit.assistant_teacher_times }}
                      <div
                        class="text-center font-weight-bold"
                        v-if="
                          assistant_teachers_edit.assistant_teacher_times ===
                            '1' ||
                            assistant_teachers_edit.assistant_teacher_times ===
                              '2'
                        "
                      >
                        ด้านที่ 1 :
                        <span
                          class="success--text"
                          v-if="
                            assistant_teachers_edit.assistant_teacher_score_a >=
                              24
                          "
                          >{{
                            assistant_teachers_edit.assistant_teacher_score_a
                          }}</span
                        >
                        <span class="red--text" v-else>{{
                          assistant_teachers_edit.assistant_teacher_score_a
                        }}</span>

                        ด้านที่ 2 :
                        <span
                          class="success--text"
                          v-if="
                            assistant_teachers_edit.assistant_teacher_score_b >=
                              36
                          "
                          >{{
                            assistant_teachers_edit.assistant_teacher_score_b
                          }}</span
                        >
                        <span class="red--text" v-else>{{
                          assistant_teachers_edit.assistant_teacher_score_b
                        }}</span>

                        <div
                          class="text-center font-weight-bold success--text"
                          v-if="
                            assistant_teachers_edit.assistant_teacher_score >=
                              60
                          "
                        >
                          คะแนนรวม :
                          {{ assistant_teachers_edit.assistant_teacher_score }}
                        </div>

                        <div
                          class="text-center font-weight-bold red--text"
                          v-else
                        >
                          คะแนนรวม :
                          {{ assistant_teachers_edit.assistant_teacher_score }}
                        </div>
                      </div>

                      <div
                        class="text-center font-weight-bold"
                        v-if="
                          assistant_teachers_edit.assistant_teacher_times ===
                            '3' ||
                            assistant_teachers_edit.assistant_teacher_times ===
                              '4'
                        "
                      >
                        ด้านที่ 1 :
                        <span
                          class="success--text"
                          v-if="
                            assistant_teachers_edit.assistant_teacher_score_a >=
                              28
                          "
                          >{{
                            assistant_teachers_edit.assistant_teacher_score_a
                          }}</span
                        >
                        <span class="red--text" v-else>{{
                          assistant_teachers_edit.assistant_teacher_score_a
                        }}</span>

                        ด้านที่ 2 :
                        <span
                          class="success--text"
                          v-if="
                            assistant_teachers_edit.assistant_teacher_score_b >=
                              42
                          "
                          >{{
                            assistant_teachers_edit.assistant_teacher_score_b
                          }}</span
                        >
                        <span class="red--text" v-else>{{
                          assistant_teachers_edit.assistant_teacher_score_b
                        }}</span>

                        <div
                          class="text-center font-weight-bold success--text"
                          v-if="
                            assistant_teachers_edit.assistant_teacher_score >=
                              70
                          "
                        >
                          คะแนนรวม :
                          {{ assistant_teachers_edit.assistant_teacher_score }}
                        </div>

                        <div
                          class="text-center font-weight-bold red--text"
                          v-else
                        >
                          คะแนนรวม :
                          {{ assistant_teachers_edit.assistant_teacher_score }}
                        </div>
                      </div>

                      <div class="text-center">
                        วันที่ประเมิน :
                        {{
                          assistant_teachers_edit.assistant_teacher_datetime
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>

                      <div class="text-center">
                        วันที่รายงาน :
                        {{
                          assistant_teachers_edit.assistant_teacher_datetimest
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                      </div>
                    </v-flex>
                    <v-flex md6>
                      <v-radio-group
                        v-model="
                          assistant_teachers_edit.assistant_teacher_ipacheck
                        "
                        row
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:label>
                          <div><strong>การตรวจสอบข้อมูล</strong></div>
                        </template>
                        <v-radio value="Pass">
                          <template v-slot:label>
                            <div>
                              <strong class="success--text">ผ่าน</strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="NotPass">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">ไม่ผ่าน</strong>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>

                    <v-flex
                      md6
                      v-if="
                        assistant_teachers_edit.assistant_teacher_ipacheck ===
                          'NotPass'
                      "
                    >
                      <v-text-field
                        label="เหตุผล"
                        v-model="
                          assistant_teachers_edit.assistant_teacher_ipacomment
                        "
                        required
                        :rules="[v => !!v || '']"
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <!--   <v-btn @click="deleteAssis()" color="red" outlined>
                <v-icon>mdi-delete</v-icon> ลบรายการประเมินในครั้งนี้</v-btn
              > -->
              <v-btn
                outlined
                color="warning"
                @click.stop="editassistant_teacherCancelSubmit()"
                rounded
              >
                <v-icon dark>mdi-close-circle</v-icon>&nbsp;ยกเลิกการตรวจ
              </v-btn>

              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                outlined
                @click.stop="editpersonnel_temporaryCheckdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>

              <v-btn
                outlined
                color="green"
                @click.stop="editassistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- ยกเลิกไฟล์แนบคำสั่งแต่งตั้งกรรมการ -->

      <v-dialog v-model="cancel_committeefiledialog" persistent max-width="60%">
        <v-card class="mx-auto pa-6">
          <base-material-card
            color="warning"
            icon="mdi-clipboard-text"
            title="การตรวจสอบข้อมูล คำสั่งแต่งตั้งกรรมการ"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="cancel_committeefiledialogform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <div class="text-center">
                      <v-btn
                        color="info"
                        icon
                        @click="
                          viewFile(
                            editmanage_assistant_teacher.pertem_assistantFile
                          )
                        "
                        ><v-icon size="60">mdi-printer</v-icon></v-btn
                      >
                    </div>
                    <br />
                    ชื่อ-นามสกุล :
                    <span class="font-weight-bold"
                      >{{ editmanage_assistant_teacher.frist_names }}
                    </span>
                    <br />
                    ID-card :
                    {{ editmanage_assistant_teacher.mt_id_card }}
                  </v-flex>
                  <v-flex md6>
                    <v-radio-group
                      v-model="
                        editmanage_assistant_teacher.pertem_assistantFile_ipacheck
                      "
                      row
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:label>
                        <div><strong>การตรวจสอบข้อมูล</strong></div>
                      </template>
                      <v-radio value="pass">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="dontpass">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">ไม่ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.pertem_assistantFile_ipacheck ===
                        'dontpass'
                    "
                  >
                    <v-text-field
                      label="เหตุผล"
                      v-model="
                        editmanage_assistant_teacher.pertem_assistantFile_ipacheck_detail
                      "
                      required
                      :rules="[v => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <strong class="red--text">* จำเป็น</strong>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              outlined
              color="warning"
              @click.stop="cancel_committeefileCancelSubmit()"
              rounded
            >
              <v-icon dark>mdi-close-circle</v-icon>&nbsp;ยกเลิกการตรวจ
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              outlined
              @click.stop="cancel_committeefiledialog = false"
              rounded
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>

            <v-btn
              outlined
              color="green"
              @click.stop="cancel_committeefiledialogSubmit()"
              rounded
            >
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- ยกเลิกไฟล์แนบใบอนุญาตประกอบวิชาชีพครู -->

      <v-dialog v-model="cancel_Licensefiledialog" persistent max-width="60%">
        <v-card class="mx-auto pa-6">
          <base-material-card
            color="warning"
            icon="mdi-clipboard-text"
            title="การตรวจสอบข้อมูล ใบอนุญาตประกอบวิชาชีพครู/ใบอนุญาตปฏิบัติการสอน"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="cancel_Licensefiledialogform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <div class="text-center">
                      <v-btn
                        v-if="
                          String(editmanage_assistant_teacher.pt_licence_file)
                            .length > 6
                        "
                        color="info"
                        icon
                        @click="
                          viewpdflicenseteach(
                            editmanage_assistant_teacher.pt_licence_file
                          )
                        "
                        ><v-icon size="60">mdi-printer</v-icon></v-btn
                      >
                    </div>

                    <div class="text-center">
                      <v-btn
                        v-if="
                          String(
                            editmanage_assistant_teacher.pt_licence_file_tem
                          ).length > 6
                        "
                        color="info"
                        icon
                        @click="
                          viewpdflicenseteach(
                            editmanage_assistant_teacher.pt_licence_file_tem
                          )
                        "
                        ><v-icon size="60">mdi-printer</v-icon></v-btn
                      >
                    </div>

                    <br />
                    ชื่อ-นามสกุล :
                    <span class="font-weight-bold"
                      >{{ editmanage_assistant_teacher.frist_names }}
                    </span>
                    <br />
                    ID-card :
                    {{ editmanage_assistant_teacher.mt_id_card }}
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.mt_license_status === 'have'
                    "
                  >
                    <v-radio-group
                      v-model="editmanage_assistant_teacher.pt_licence_file_ipa"
                      row
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:label>
                        <div><strong>การตรวจสอบข้อมูล</strong></div>
                      </template>
                      <v-radio value="pass">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="dontpass">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">ไม่ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.pt_licence_file_ipa ===
                        'dontpass' &&
                        editmanage_assistant_teacher.mt_license_status ===
                          'have'
                    "
                  >
                    <v-text-field
                      label="เหตุผล"
                      v-model="
                        editmanage_assistant_teacher.pt_licence_file_ipacomment
                      "
                      required
                      :rules="[v => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.mt_license_status === 'donot'
                    "
                  >
                    <v-radio-group
                      v-model="
                        editmanage_assistant_teacher.pt_licence_file_ipa_tem
                      "
                      row
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:label>
                        <div>
                          การตรวจสอบข้อมูล<strong>
                            ใบอนุญาตปฏิบัติการสอน</strong
                          >
                        </div>
                      </template>
                      <v-radio value="pass">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="dontpass">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">ไม่ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.pt_licence_file_ipa_tem ===
                        'dontpass' &&
                        editmanage_assistant_teacher.mt_license_status ===
                          'donot'
                    "
                  >
                    <v-text-field
                      label="เหตุผล"
                      v-model="
                        editmanage_assistant_teacher.pt_licence_file_ipacomment_tem
                      "
                      required
                      :rules="[v => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <strong class="red--text">* จำเป็น</strong>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              outlined
              @click.stop="cancel_Licensefiledialog = false"
              rounded
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>

            <v-btn
              outlined
              color="green"
              @click.stop="cancel_LicensefiledialogSubmit()"
              rounded
            >
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Assistant_teacher_bar from "../../components/admin/assistant_teacher_bar.vue";

export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addpersonnel_temporarydialog: false,
      editpersonnel_temporarydialog: false,
      deletepersonnel_temporarydialog: false,
      evaluate_assistantdialog: false,
      assistant_teacherdialog: false,
      assistant_teacherEditdialog: false,
      assistant_teacherConfirmdialog: false,
      cancel_assistant_teacher_reportdialog: false,
      pertem_assistantFileCCdialog: false,
      editpersonnel_temporaryCheckdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      personnel_temporarys: [],
      addpersonnel_temporary: {},
      editpersonnel_temporary: {},
      search: "",
      searchCC: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "ลำดับ", align: "center", value: "index_s" },
        { text: "รหัสสถานศึกษา", align: "left", value: "user_code" },
        { text: "สถานศึกษา", align: "left", value: "agency" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ภาค", align: "left", value: "region_name" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "สกุล", align: "left", value: "mt_last_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "mt_id_position" },

        { text: "ใบประกอบวิชาชีพ", align: "center", value: "pt_licence" },
        {
          text: "เสนอรายชื่อกรรมการ",
          align: "center",
          value: "mt_committe_file"
        },

        {
          text: "คำสั่งแต่งตั้งกรรมการประเมิน",
          align: "center",
          value: "pertem_assistantFile"
        },
        { text: "ครั้งที่ 1(1) ", align: "center", value: "mt_time_1_score_a" },
        { text: "ครั้งที่ 1(2) ", align: "center", value: "mt_time_1_score_b" },
        { text: "ครั้งที่ 1 ", align: "center", value: "mt_time_1_score" },

        { text: "ครั้งที่ 2(1) ", align: "center", value: "mt_time_2_score_a" },
        { text: "ครั้งที่ 2(2) ", align: "center", value: "mt_time_2_score_b" },
        { text: "ครั้งที่ 2 ", align: "center", value: "mt_time_2_score" },

        { text: "ครั้งที่ 3(1) ", align: "center", value: "mt_time_3_score_a" },
        { text: "ครั้งที่ 3(2) ", align: "center", value: "mt_time_3_score_b" },
        { text: "ครั้งที่ 3 ", align: "center", value: "mt_time_3_score" },

        { text: "ครั้งที่ 4(1) ", align: "center", value: "mt_time_4_score_a" },
        { text: "ครั้งที่ 4(2) ", align: "center", value: "mt_time_4_score_b" },
        { text: "ครั้งที่ 4 ", align: "center", value: "mt_time_4_score" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "mt_date_app_now" }
        /*   { text: "คำสั่ง", align: "center", value: "mt_order_app" } */
      ],
      headers_aassistant_teacher: [
        { text: "ครั้งที่", align: "center", value: "assistant_teacher_times" },
        {
          text: "สถานศึกษา",
          align: "left",
          value: "college_name"
        },
        {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "assistant_teacher_mt_id_card"
        },
        {
          text: "ชื่อ-นามสกุล",
          align: "left",
          value: "mt_frist_names"
        },
        {
          text: "วันที่ประเมิน",
          align: "center",
          value: "assistant_teacher_datetime"
        },
        { text: "คะแนน", align: "center", value: "assistant_teacher_score" },
        { text: "สถานะ", align: "center", value: "assistant_teacher_status" },
        {
          text: "หมายเหตุ",
          align: "center",
          value: "assistant_teacher_comment"
        }
      ],
      rang_names: [
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      rang_levels: ["-", 1, 2, 3, 4, 5],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      select_per: "",
      select_personel: [
        { text: "ประเมินครั้งที่ 1 ", value: "1" },
        { text: "ประเมินครั้งที่ 2 ", value: "2" },
        { text: "ประเมินครั้งที่ 3 ", value: "3" },
        { text: "ประเมินครั้งที่ 4 ", value: "4" }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      userstatus: [],
      man_powers: [],
      collgegs: [],
      personnel_temporarystatus: [],
      regions: [],
      region_ena: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      personnel_temporary_mt_id_card: [],
      assistant_teachers: [],
      addassistant_teacher: {},
      assistant_teachers_edit: [],
      assistant_teachers_confrim: {},
      assistant_teacher_report_s: {},
      result_assistant_teacher_reports: {},
      cancel_assistant_teacher_reports: {},
      cancel_assistant_teachers: {},
      data_syslog: {},
      colleges: [],
      college_select: "",
      Updatepersonnel_temporary: {},
      groupDateTimeApps: [],
      dateTimeAppSelect: "",
      dateTimeAppSelect: "",
      pdflicenseteachdialog: false,
      pt_licence_files: "",
      filesAss: "",
      assistant_teacher_filedialog: false,
      countCancel: "",
      headersAlert: [
        { text: "วันที่บรรจุ", align: "center", value: "mt_date_app_now" },

        {
          text: "รหัสสถานศึกษา",
          align: "left",
          value: "user_code"
        },
        { text: "สถานศึกษา", align: "center", value: "user_firstname" },
        {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "assistant_teacher_id_card"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        {
          text: "ครั้งที่ประเมิน",
          align: "center",
          value: "assistant_teacher_times"
        },
        {
          text: "วันที่ประเมิน",
          align: "center",
          value: "assistant_teacher_datetime"
        },
        { text: "คะแนน", align: "center", value: "assistant_teacher_score" },

        { text: "สถานะ", align: "center", value: "assistant_teacher_status" },

        {
          text: "รายละเอียดแจ้ง",
          align: "center",
          value: "assistant_teacher_comment_detail"
        },

        {
          text: "ดำเนินการ",
          align: "center",
          value: "assistant_teacher_ipacheck"
        },

        {
          text: "หมายเหตุ",
          align: "center",
          value: "assistant_teacher_ipacomment"
        }
      ],
      check_param_query: "",
      countCC: "",
      upManageAss: {},
      updateorder_app_now: {},
      updateorder_app_now_ms: {},
      tabs: null,
      Updatemanage_assistant_teacher: {},
      assistant_teachersCC: [],
      editmanage_assistant_teacher: [],
      cancel_committeefiledialog: false,
      cancel_Licensefiledialog: false,
      checkdatareport: [],
      timelowscore: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    let result;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;

    let user_status_result;
    user_status_result = await this.$http.post(
      "crud_user_status.php?crud=read",
      {
        ApiKey: this.ApiKey
      }
    );
    this.userstatus = user_status_result.data;
    await this.groupDateTimeAppQuery();
    await this.checkappoint();

    await this.assistant_teacherscollegeCCQueryAll();
    await this.assistant_teachersCCQueryAll();
  },
  methods: {
    async cancel_LicensefiledialogSubmit() {
      if (this.$refs.cancel_Licensefiledialogform.validate()) {
        let textAlert =
          this.editmanage_assistant_teacher.mt_title_s +
          this.editmanage_assistant_teacher.mt_frist_name +
          " " +
          this.editmanage_assistant_teacher.mt_last_name;
        Swal.fire({
          title: "การตรวจสอบ",
          text: textAlert,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก"
        }).then(async result => {
          if (result.isConfirmed) {
            this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
            if (
              this.editmanage_assistant_teacher.pt_licence_file_ipa ==
              "dontpass"
            ) {
              let result_del = await this.$http.post("deletefile.php", {
                ApiKey: this.ApiKey,
                filename:
                  "../HRvecLicenseTeach/" +
                  this.editmanage_assistant_teacher.pt_licence_file
              });

              this.editmanage_assistant_teacher.pt_licence_file = "";
            }

            let result = await this.$http.post(
              "manage_assistant_teacher.update.php",
              this.editmanage_assistant_teacher
            );

            if (result.data.status == true) {
              this.manage_assistant_teacher = result.data;
              Swal.fire({
                icon: "success",
                title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });

              await this.checkappoint();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        });
        this.cancel_Licensefiledialog = false;
      }
    },
    async updateLicense(mt_id) {
      this.editmanage_assistant_teacher = [];
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.cancel_Licensefiledialog = true;
    },
    async updateAssisstant(mt_id) {
      this.editmanage_assistant_teacher = [];
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.cancel_committeefiledialog = true;
    },

    async cancel_committeefileCancelSubmit() {
      let textAlert =
        this.editmanage_assistant_teacher.mt_title_s +
        this.editmanage_assistant_teacher.mt_frist_name +
        " " +
        this.editmanage_assistant_teacher.mt_last_name;
      Swal.fire({
        title: "ต้องการยกเลิกการตรวจสอบ",
        text: textAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;

          this.editmanage_assistant_teacher.pertem_assistantFile = "";
          this.editmanage_assistant_teacher.pertem_assistantFileCC = "";

          let result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );

          if (result.data.status == true) {
            this.manage_assistant_teacher = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });

            await this.checkappoint();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async cancel_committeefiledialogSubmit() {
      let textAlert =
        this.editmanage_assistant_teacher.mt_title_s +
        this.editmanage_assistant_teacher.mt_frist_name +
        " " +
        this.editmanage_assistant_teacher.mt_last_name;
      Swal.fire({
        title: "ยืนยันการตรวจสอบ การแนบไฟล์คำสั่งแต่งตั้งคณะกรรมการประเมิน ?",
        text: textAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;

          if (
            this.editmanage_assistant_teacher.pertem_assistantFile_ipacheck ==
            "dontpass"
          ) {
            result = await this.$http.post("deletefile.php", {
              ApiKey: this.ApiKey,
              filename:
                "../HRvecfiles/" +
                this.editmanage_assistant_teacher.pertem_assistantFile
            });

            this.editmanage_assistant_teacher.pertem_assistantFile = "";
            this.editmanage_assistant_teacher.pertem_assistantFileCC = "";
          }

          let result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );

          if (result.data.status == true) {
            this.manage_assistant_teacher = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });

            await this.checkappoint();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.cancel_committeefiledialog = false;
      });
    },

    async assistant_teacher_update() {
      await this.sweetAlertLoading();
      let result = await this.$http.post(
        "manage_assistant_teacher.updateadmin.clear.php",
        {
          ApiKey: this.ApiKey
        }
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      }
      Swal.close();
    },
    /*     async deleteAssis() {
      let textAlert =
        "ครั้งที่ " +
        this.assistant_teachers_edit.assistant_teacher_times +
        " : " +
        this.assistant_teachers_edit.frist_names;
      Swal.fire({
        title: "ลบรายการคะแนนนี้",
        text: textAlert,
        icon: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ยืนยันยกเลิก",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.assistant_teachers_edit.ApiKey = this.ApiKey;
          let resultPic = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.assistant_teachers_edit.assistant_teacher_file
          });
          let result = await this.$http.post(
            "assistant_teacher.delete.php",
            this.assistant_teachers_edit
          );
          this.Updatemanage_assistant_teacher.ApiKey = this.ApiKey;
          this.Updatemanage_assistant_teacher.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;
          if (this.assistant_teachers_edit.assistant_teacher_times == "1") {
            this.Updatemanage_assistant_teacher.mt_time_1_file = "";
            this.Updatemanage_assistant_teacher.mt_time_1_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_1_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_1_score = "";
            this.Updatemanage_assistant_teacher.mt_time_1_ck = "";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "2"
          ) {
            this.Updatemanage_assistant_teacher.mt_time_2_file = "";
            this.Updatemanage_assistant_teacher.mt_time_2_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_2_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_2_score = "";
            this.Updatemanage_assistant_teacher.mt_time_2_ck = "";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "3"
          ) {
            this.Updatemanage_assistant_teacher.mt_time_3_file = "";
            this.Updatemanage_assistant_teacher.mt_time_3_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_3_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_3_score = "";
            this.Updatemanage_assistant_teacher.mt_time_3_ck = "";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "4"
          ) {
            this.Updatemanage_assistant_teacher.mt_time_4_file = "";
            this.Updatemanage_assistant_teacher.mt_time_4_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_4_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_4_score = "";
            this.Updatemanage_assistant_teacher.mt_time_4_ck = "";
          }

          let result_m = await this.$http.post(
            "manage_assistant_teacher.update.idcard.php",
            this.Updatemanage_assistant_teacher
          );

          if (result.data.status == true || result_m.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.checkappoint();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.editpersonnel_temporaryCheckdialog = false;
      });
    }, */
    async updateOrder() {
      this.updateorder_app_now.ApiKey = this.ApiKey;
      this.updateorder_app_now.appoin_day = this.appoin_day;
      this.updateorder_app_now.appoin_month = this.appoin_month;
      this.updateorder_app_now.appoin_year = this.appoin_year;

      this.updateorder_app_now_ms.ApiKey = this.ApiKey;
      this.updateorder_app_now_ms.mt_appoin_day = this.appoin_day;
      this.updateorder_app_now_ms.mt_appoin_month = this.appoin_month;
      this.updateorder_app_now_ms.mt_appoin_year = this.appoin_year;
      this.updateorder_app_now_ms.mt_order_app = this.updateorder_app_now.order_app_now;

      let result_pt = await this.$http.post(
        "personnel_temporary.update.admin.order.php",
        this.updateorder_app_now
      );
      let result_mt = await this.$http.post(
        "manage_assistant_teacher.updateadmin.order.php",
        this.updateorder_app_now_ms
      );
      if (result_pt.data.status == true || result_mt.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.checkappoint();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async UpdateSystem() {
      this.loading = true;
      let result = await this.$http
        .post("update_manage_assistant_teacher.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
    },
    async cancelAssistant(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;

      let textAlert =
        this.assistant_teachers_edit.frist_names +
        " ครั้งที่ : " +
        this.assistant_teachers_edit.assistant_teacher_times;
      Swal.fire({
        title: "ยกเลิกการรายงานคะแนน",
        text: textAlert,
        icon: "warning",
        showDenyButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยันยกเลิก",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `ไม่อนุญาต`
      }).then(async result => {
        if (result.isDenied) {
          this.assistant_teachers_edit.ApiKey = this.ApiKey;
          this.assistant_teachers_edit.assistant_teacher_comment = "";
          let result = await this.$http.post(
            "assistant_teacher.update.php",
            this.assistant_teachers_edit
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.assistant_teachersCCQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else if (result.isConfirmed) {
          this.assistant_teachers_edit.ApiKey = this.ApiKey;
          let resultPic = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.assistant_teachers_edit.assistant_teacher_file
          });

          this.assistant_teachers_edit.assistant_teacher_file = "";
          this.assistant_teachers_edit.assistant_teacher_comment = "CC";
          this.assistant_teachers_edit.assistant_teacher_comment_detail = "";
          this.assistant_teachers_edit.assistant_teacher_ipacheck = "";
          this.assistant_teachers_edit.assistant_teacher_ipacomment = "";
          let result = await this.$http.post(
            "assistant_teacher.update.php",
            this.assistant_teachers_edit
          );

          this.Updatemanage_assistant_teacher.ApiKey = this.ApiKey;
          this.Updatemanage_assistant_teacher.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;
          if (this.assistant_teachers_edit.assistant_teacher_times == "1") {
            this.Updatemanage_assistant_teacher.mt_time_1_file = "";
            this.Updatemanage_assistant_teacher.mt_time_1_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_1_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_1_score = "";
            this.Updatemanage_assistant_teacher.mt_time_1_ck = "";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "2"
          ) {
            this.Updatemanage_assistant_teacher.mt_time_2_file = "";
            this.Updatemanage_assistant_teacher.mt_time_2_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_2_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_2_score = "";
            this.Updatemanage_assistant_teacher.mt_time_2_ck = "";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "3"
          ) {
            this.Updatemanage_assistant_teacher.mt_time_3_file = "";
            this.Updatemanage_assistant_teacher.mt_time_3_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_3_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_3_score = "";
            this.Updatemanage_assistant_teacher.mt_time_3_ck = "";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "4"
          ) {
            this.Updatemanage_assistant_teacher.mt_time_4_file = "";
            this.Updatemanage_assistant_teacher.mt_time_4_score_a = "";
            this.Updatemanage_assistant_teacher.mt_time_4_score_b = "";
            this.Updatemanage_assistant_teacher.mt_time_4_score = "";
            this.Updatemanage_assistant_teacher.mt_time_4_ck = "";
          }

          let result_m = await this.$http.post(
            "manage_assistant_teacher.update.idcard.php",
            this.Updatemanage_assistant_teacher
          );

          if (result.data.status == true || result_m.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.assistant_teachersCCQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_lowscore: "ok"
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },

    async Upmanage_assistant(assistant_teacher_id) {
      let timeid = this.assistant_teachers_edit.assistant_teacher_times;
      if (timeid == "1") {
        this.upManageAss.ApiKey = this.ApiKey;
        this.upManageAss.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;
        this.upManageAss.mt_college_code = this.assistant_teachers_edit.assistant_teacher_college_code;
        this.upManageAss.mt_time_1_id = assistant_teacher_id;
        this.upManageAss.mt_time_1_score = this.assistant_teachers_edit.assistant_teacher_score;
        this.upManageAss.mt_time_1_file = this.assistant_teachers_edit.assistant_teacher_file;
        this.upManageAss.mt_time_1_ck = this.assistant_teachers_edit.assistant_teacher_ipacheck;
      } else if (timeid == "2") {
        this.upManageAss.ApiKey = this.ApiKey;
        this.upManageAss.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;
        this.upManageAss.mt_college_code = this.assistant_teachers_edit.assistant_teacher_college_code;
        this.upManageAss.mt_time_2_id = assistant_teacher_id;
        this.upManageAss.mt_time_2_score = this.assistant_teachers_edit.assistant_teacher_score;
        this.upManageAss.mt_time_2_file = this.assistant_teachers_edit.assistant_teacher_file;
        this.upManageAss.mt_time_2_ck = this.assistant_teachers_edit.assistant_teacher_ipacheck;
      } else if (timeid == "3") {
        this.upManageAss.ApiKey = this.ApiKey;
        this.upManageAss.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;
        this.upManageAss.mt_college_code = this.assistant_teachers_edit.assistant_teacher_college_code;
        this.upManageAss.mt_time_3_id = assistant_teacher_id;
        this.upManageAss.mt_time_3_score = this.assistant_teachers_edit.assistant_teacher_score;
        this.upManageAss.mt_time_3_file = this.assistant_teachers_edit.assistant_teacher_file;
        this.upManageAss.mt_time_3_ck = this.assistant_teachers_edit.assistant_teacher_ipacheck;
      } else if (timeid == "4") {
        this.upManageAss.ApiKey = this.ApiKey;
        this.upManageAss.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;
        this.upManageAss.mt_college_code = this.assistant_teachers_edit.assistant_teacher_college_code;
        this.upManageAss.mt_time_4_id = assistant_teacher_id;
        this.upManageAss.mt_time_4_score = this.assistant_teachers_edit.assistant_teacher_score;
        this.upManageAss.mt_time_4_file = this.assistant_teachers_edit.assistant_teacher_file;
        this.upManageAss.mt_time_4_ck = this.assistant_teachers_edit.assistant_teacher_ipacheck;
      }

      let resultUp = await this.$http.post(
        "manage_assistant_teacher.update.idcard.php",
        this.upManageAss
      );
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },
    async editassistant_teacherCancelSubmit() {
      await this.sweetAlertLoading();
      this.assistant_teachers_edit.ApiKey = this.ApiKey;

      if (this.assistant_teachers_edit.assistant_teacher_times == "1") {
        this.Updatepersonnel_temporary.mt_time_1_ck = "";
      } else if (this.assistant_teachers_edit.assistant_teacher_times == "2") {
        this.Updatepersonnel_temporary.mt_time_2_ck = "";
      } else if (this.assistant_teachers_edit.assistant_teacher_times == "3") {
        this.Updatepersonnel_temporary.mt_time_3_ck = "";
      } else if (this.assistant_teachers_edit.assistant_teacher_times == "4") {
        this.Updatepersonnel_temporary.mt_time_4_ck = "";
      }

      this.assistant_teachers_edit.assistant_teacher_ipacheck = "";
      this.assistant_teachers_edit.assistant_teacher_ipacomment = "";

      this.assistant_teachers_edit.assistant_teacher_comment = "";
      this.assistant_teachers_edit.assistant_teacher_comment_detail = "";

      this.Updatepersonnel_temporary.ApiKey = this.ApiKey;
      this.Updatepersonnel_temporary.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;

      let result_m = await this.$http.post(
        "manage_assistant_teacher.update.idcard.php",
        this.Updatepersonnel_temporary
      );

      let result = await this.$http.post(
        "assistant_teacher.update.php",
        this.assistant_teachers_edit
      );
      if (result.data.status == true || result_m.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.editpersonnel_temporaryCheckdialog = false;
      await this.checkappoint();

      await this.assistant_teacherscollegeCCQueryAll();
      await this.assistant_teachersCCQueryAll();
      Swal.close();
    },
    async editassistant_teacherSubmit() {
      if (this.$refs.editpersonnel_temporaryCheckform.validate()) {
        await this.sweetAlertLoading();
        this.assistant_teachers_edit.ApiKey = this.ApiKey;
        this.Updatepersonnel_temporary = {};
        this.Updatepersonnel_temporary.ApiKey = this.ApiKey;
        let result_m = "";
        this.Updatepersonnel_temporary.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;
        if (
          this.assistant_teachers_edit.assistant_teacher_ipacheck == "NotPass"
        ) {
          if (this.assistant_teachers_edit.assistant_teacher_times == "1") {
            this.Updatepersonnel_temporary.mt_time_1_file = "";
            this.Updatepersonnel_temporary.mt_time_1_ck = "NotPass";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "2"
          ) {
            this.Updatepersonnel_temporary.mt_time_2_file = "";
            this.Updatepersonnel_temporary.mt_time_2_ck = "NotPass";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "3"
          ) {
            this.Updatepersonnel_temporary.mt_time_3_file = "";
            this.Updatepersonnel_temporary.mt_time_3_ck = "NotPass";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "4"
          ) {
            this.Updatepersonnel_temporary.mt_time_4_file = "";
            this.Updatepersonnel_temporary.mt_time_4_ck = "NotPass";
          }

          let resultFiles = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.assistant_teachers_edit.assistant_teacher_file
          });

          this.assistant_teachers_edit.assistant_teacher_file = "";
          this.assistant_teachers_edit.assistant_teacher_comment = "CC";
          this.assistant_teachers_edit.assistant_teacher_comment_detail = "";
          result_m = await this.$http.post(
            "manage_assistant_teacher.update.idcard.php",
            this.Updatepersonnel_temporary
          );
        }

        if (this.assistant_teachers_edit.assistant_teacher_ipacheck == "Pass") {
          if (this.assistant_teachers_edit.assistant_teacher_times == "1") {
            this.Updatepersonnel_temporary.mt_time_1_ck = "Pass";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "2"
          ) {
            this.Updatepersonnel_temporary.mt_time_2_ck = "Pass";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "3"
          ) {
            this.Updatepersonnel_temporary.mt_time_3_ck = "Pass";
          } else if (
            this.assistant_teachers_edit.assistant_teacher_times == "4"
          ) {
            this.Updatepersonnel_temporary.mt_time_4_ck = "Pass";
          }

          this.assistant_teachers_edit.assistant_teacher_ipacomment = "";
          this.assistant_teachers_edit.assistant_teacher_comment = "";
          this.assistant_teachers_edit.assistant_teacher_comment_detail = "";
          result_m = await this.$http.post(
            "manage_assistant_teacher.update.idcard.php",
            this.Updatepersonnel_temporary
          );
        }

        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.assistant_teachers_edit
        );
        if (result.data.status == true || result_m.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_temporaryCheckdialog = false;
        await this.checkappoint();
        await this.assistant_teacherscollegeCCQueryAll();
        await this.assistant_teachersCCQueryAll();

        Swal.close();
      }
    },

    async checkAssistant(assistant_teacher_id) {
      this.assistant_teachers_edit = [];
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.editpersonnel_temporaryCheckdialog = true;
    },

    async viewFile(assistant_teacher_file) {
      this.filesAss = assistant_teacher_file;
      this.assistant_teacher_filedialog = true;
    },

    async viewpdflicenseteach(pt_licence_file) {
      this.pt_licence_files = pt_licence_file;
      let pt_licence_fileCk = String(pt_licence_file);
      if (pt_licence_fileCk.length > 4) {
        this.pdflicenseteachdialog = true;
      } else {
        Swal.fire({
          icon: "warning",
          title: "ไม่ได้แนบไฟล์",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async checkappoint() {
      try{
      await this.sweetAlertLoading();
      let appoin_day = this.appoin_day || 0;
      let appoin_month = this.appoin_month || 0;
      let appoin_year = this.appoin_year || 0;
      let dates = new Date(appoin_year + "-" + appoin_month + "-" + appoin_day);
      let getTime = dates.getTime();
      this.check_param_query = String(dates.getSeconds).length;
    
        if (getTime > 1) {
        this.loading = true;
        let result = await this.$http
          .post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            mt_appoin_day: appoin_day,
            mt_appoin_month: appoin_month,
            mt_appoin_year: appoin_year,
            fastQ: "ok"
          })      
        this.personnel_temporarys = result.data;
        let data = result.data;
        let counts = 0;
        let counttime1pass = 0;
        let counttime1notpass = 0;
        let counttime1notcheck = 0;
        let counttime1null = 0;
        let counttime1lowscore = 0;

        let counttime2pass = 0;
        let counttime2notpass = 0;
        let counttime2notcheck = 0;
        let counttime2null = 0;
        let counttime2lowscore = 0;

        let counttime3pass = 0;
        let counttime3notpass = 0;
        let counttime3notcheck = 0;
        let counttime3null = 0;
        let counttime3lowscore = 0;

        let counttime4pass = 0;
        let counttime4notpass = 0;
        let counttime4notcheck = 0;
        let counttime4null = 0;
        let counttime4lowscore = 0;

        data.forEach(value => {
          counts += 1;
          if (value.mt_time_1_ck == "Pass") {
            counttime1pass += 1;
          }
          if (value.mt_time_1_ck == "NotPass") {
            counttime1notpass += 1;
          }
          if (
            value.mt_time_1_score > 0 &&
            (value.mt_time_1_ck != "Pass" || value.mt_time_1_ck != "NotPass")
          ) {
            counttime1notcheck += 1;
          }
          if (value.mt_time_1_score <= 0) {
            counttime1null += 1;
          }
          if ((value.mt_time_1_score_a > 0 && value.mt_time_1_score_a < 24) || (value.mt_time_1_score_b > 0 && value.mt_time_1_score_b < 36)) {
            counttime1lowscore += 1;
          }

          /*  */
          if (value.mt_time_2_ck == "Pass") {
            counttime2pass += 1;
          }
          if (value.mt_time_2_ck == "NotPass") {
            counttime2notpass += 1;
          }
          if (
            value.mt_time_2_score > 0 &&
            (value.mt_time_2_ck != "Pass" || value.mt_time_2_ck != "NotPass")
          ) {
            counttime2notcheck += 1;
          }
          if (value.mt_time_2_score <= 0) {
            counttime2null += 1;
          }
          if ((value.mt_time_2_score_a > 0 && value.mt_time_2_score_a < 24) || (value.mt_time_2_score_b > 0 && value.mt_time_2_score_b < 36)) {
            counttime2lowscore += 1;
          }

          /*  */
          if (value.mt_time_3_ck == "Pass") {
            counttime3pass += 1;
          }
          if (value.mt_time_3_ck == "NotPass") {
            counttime3notpass += 1;
          }
          if (
            value.mt_time_3_score > 0 &&
            (value.mt_time_3_ck != "Pass" || value.mt_time_3_ck != "NotPass")
          ) {
            counttime3notcheck += 1;
          }
          if (value.mt_time_3_score <= 0) {
            counttime3null += 1;
          }

          if ((value.mt_time_3_score_a > 0 && value.mt_time_3_score_a < 28) || (value.mt_time_3_score_b >0 && value.mt_time_3_score_b < 42)) {
            counttime3lowscore += 1;
          }

          /*  */
          if (value.mt_time_4_ck == "Pass") {
            counttime4pass += 1;
          }
          if (value.mt_time_4_ck == "NotPass") {
            counttime4notpass += 1;
          }
          if (
            value.mt_time_4_score > 0 &&
            (value.mt_time_4_ck != "Pass" || value.mt_time_4_ck != "NotPass")
          ) {
            counttime4notcheck += 1;
          }
          if (value.mt_time_4_score <= 0) {
            counttime4null += 1;
          }

          if ((value.mt_time_4_score_a > 0 && value.mt_time_4_score_a < 28) || (value.mt_time_4_score_b >0 && value.mt_time_4_score_b < 42)) {
            counttime4lowscore += 1;
          }
        });
        this.checkdatareport.countall = counts;
        this.checkdatareport.counttime1pass = counttime1pass;
        this.checkdatareport.counttime1notpass = counttime1notpass;
        this.checkdatareport.counttime1notcheck = counttime1notcheck;
        this.checkdatareport.counttime1null = counttime1null;
        this.timelowscore.counttime1lowscore = counttime1lowscore;

        this.checkdatareport.counttime2pass = counttime2pass;
        this.checkdatareport.counttime2notpass = counttime2notpass;
        this.checkdatareport.counttime2notcheck = counttime2notcheck;
        this.checkdatareport.counttime2null = counttime2null;
        this.timelowscore.counttime2lowscore = counttime2lowscore;

        this.checkdatareport.counttime3pass = counttime3pass;
        this.checkdatareport.counttime3notpass = counttime3notpass;
        this.checkdatareport.counttime3notcheck = counttime3notcheck;
        this.checkdatareport.counttime3null = counttime3null;
        this.timelowscore.counttime3lowscore = counttime3lowscore;


        this.checkdatareport.counttime4pass = counttime4pass;
        this.checkdatareport.counttime4notpass = counttime4notpass;
        this.checkdatareport.counttime4notcheck = counttime4notcheck;
        this.checkdatareport.counttime4null = counttime4null;
        this.timelowscore.counttime4lowscore = counttime4lowscore;
      } else {
        await this.assistant_teacherQueryAll();
      }
      }finally{
        this.loading = false;
        Swal.close();
      } 

      Swal.close();
    },

    async checkmanage_assistant_teacherid(e) {
      if (e.keyCode == 13) {
        this.loading = true;
        let result = await this.$http
          .post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            mt_id_card: this.search,
            fastQ: "ok"
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        result.data;
      }
    },

    async personnel_temporaryDateApp() {
      let dateTimeApp = this.dateTimeAppSelect.split("-");
      let appoin_day = dateTimeApp[0];
      let appoin_month = dateTimeApp[1];
      let appoin_year = dateTimeApp[2];
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        appoin_day: appoin_day,
        appoin_month: appoin_month,
        appoin_year: appoin_year
      });
      this.personnel_temporarys = result.data;
    },
    async groupDateTimeAppQuery() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        groUPDATETimeApp: "Ok"
      });
      this.groupDateTimeApps = result.data;
    },
    async pertem_assistantFileCCSubmit() {
      this.Updatepersonnel_temporary.ApiKey = this.ApiKey;
      this.Updatepersonnel_temporary.id_card = this.editpersonnel_temporary.id_card;
      this.Updatepersonnel_temporary.mt_id_card = this.editpersonnel_temporary.id_card;
      this.Updatepersonnel_temporary.pertem_assistantFileCC = "";
      let resultPic = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HRvecfiles/" + this.editpersonnel_temporary.pertem_assistantFile
      });

      this.Updatepersonnel_temporary.pertem_assistantFile = "";
      this.Updatepersonnel_temporary.mt_collegeCC = "";
      this.Updatepersonnel_temporary.mt_committe_status = "";
      this.Updatepersonnel_temporary.mt_committe_file = "";
      this.Updatepersonnel_temporary.mt_committe_datetime = "";

      let result = await this.$http.post(
        "personnel_temporary.update.php",
        this.Updatepersonnel_temporary
      );

      let result_m = await this.$http.post(
        "manage_assistant_teacher.update.idcard.php",
        this.Updatepersonnel_temporary
      );

      if (result.data.status == true || resultPic.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.pertem_assistantFileCCdialog = false;
    },
    async pertem_assistantFileCC(mt_id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: mt_id_card
      });
      this.editpersonnel_temporary = result.data;
      this.pertem_assistantFileCCdialog = true;
    },

    async cancel_assistant_teacher_report() {
      this.cancel_assistant_teacher_reportdialog = true;
    },
    async evaluate_assistant(mt_id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        mt_id_card: mt_id_card
      });
      this.personnel_temporary_mt_id_card = result.data;
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_mt_id_card: mt_id_card
      });
      this.assistant_teachers = result_assistant.data;
      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.php",
        {
          ApiKey: this.ApiKey,
          assistant_teacher_re_mt_id_card: mt_id_card
        }
      );
      this.result_assistant_teacher_reports =
        result_assistant_teacher_report.data;
      this.evaluate_assistantdialog = true;
    },

    async assistant_teacher_success() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย",
          evalution_succ: "success"
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async assistant_teacherscollegeCCQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_comment: "collegeCC"
        })
        .finally(() => (this.loading = false));
      this.assistant_teachers = result.data;
    },

    async assistant_teachersCCQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_comment: "CC"
        })
        .finally(() => (this.loading = false));
      this.assistant_teachersCC = result.data;
    },

    async assistant_teacher_not_evalution() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย",
          not_evalution: "check"
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async assistant_teacher_add() {
      this.addassistant_teacher = {};
      this.assistant_teacherdialog = true;
    },
    async assistant_teacherEdit(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherEditdialog = true;
    },
    async personnel_temporaryQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย"
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async personnel_temporaryCollegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          fastQ: "ok",
          mt_college_code: this.college_select
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async assistant_teacherdialogSubmit() {
      if (this.$refs.assistant_teacherdialogform.validate()) {
        this.addassistant_teacher.ApiKey = this.ApiKey;
        this.addassistant_teacher.assistant_teacher_user_code = this.personnel_temporary_mt_id_card.user_code;
        this.addassistant_teacher.assistant_teacher_mt_id_card = this.personnel_temporary_mt_id_card.mt_id_card;
        this.addassistant_teacher.assistant_teacher_times = this.times_s;

        let result = await this.$http.post(
          "assistant_teacher.insert.php",
          this.addassistant_teacher
        );
        if (result.data.status == true) {
          let result = await this.$http.post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            mt_id_card: this.personnel_temporary_mt_id_card.mt_id_card
          });
          this.personnel_temporary_mt_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_mt_id_card: this.personnel_temporary_mt_id_card
                .mt_id_card
            }
          );
          this.assistant_teachers = result_assistant.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "assistant_teacher";
          this.data_syslog.table_log = "assistant_teacher";
          this.data_syslog.detail_log = this.addassistant_teacher.assistant_teacher_mt_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherdialog = false;
      }
    },
    async assistant_teacherEditdialogSubmit() {
      if (this.$refs.assistant_teacherEditform.validate()) {
        this.assistant_teachers_edit.ApiKey = this.ApiKey;

        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.assistant_teachers_edit
        );
        if (result.data.status == true) {
          let result = await this.$http.post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            mt_id_card: this.personnel_temporary_mt_id_card.mt_id_card
          });
          this.personnel_temporary_mt_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_mt_id_card: this.personnel_temporary_mt_id_card
                .mt_id_card
            }
          );
          this.assistant_teachers = result_assistant.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "assistant_teacher";
          this.data_syslog.table_log = "assistant_teacher";
          this.data_syslog.detail_log = this.assistant_teachers_edit.assistant_teacher_mt_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherEditdialog = false;
      }
    },
    async evaluate_assistantSubmit() {
      this.assistant_teacherConfirmdialog = true;
    },
    async assistant_teacherConfirmdialogSubmit() {
      this.assistant_teachers_confrim.ApiKey = this.ApiKey;
      this.assistant_teachers_confrim.assistant_teacher_mt_id_card = this.personnel_temporary_mt_id_card.mt_id_card;
      this.assistant_teachers_confrim.assistant_teacher_status = "confirm";
      this.assistant_teacher_report_s.ApiKey = this.ApiKey;
      this.assistant_teacher_report_s.assistant_teacher_re_user_code = this.personnel_temporary_mt_id_card.user_code;
      this.assistant_teacher_report_s.assistant_teacher_re_mt_id_card = this.personnel_temporary_mt_id_card.mt_id_card;
      this.assistant_teacher_report_s.assistant_teacher_re_datetime = this.date_today;
      this.assistant_teacher_report_s.assistant_teacher_re_avg_score = this.personnel_temporary_mt_id_card.avg_score;

      let result = await this.$http.post(
        "assistant_teacher.update.confirm.php",
        this.assistant_teachers_confrim
      );
      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.insert.php",
        this.assistant_teacher_report_s
      );
      if (
        result.data.status == true &&
        result_assistant_teacher_report.data.status == true
      ) {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          mt_id_card: this.personnel_temporary_mt_id_card.mt_id_card
        });
        this.personnel_temporary_mt_id_card = result.data;
        let result_assistant = await this.$http.post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_mt_id_card: this.personnel_temporary_mt_id_card
            .mt_id_card
        });
        let result_assistant_teacher_report = await this.$http.post(
          "assistant_teacher_report.php",
          {
            ApiKey: this.ApiKey,
            assistant_teacher_re_mt_id_card: this.personnel_temporary_mt_id_card
              .mt_id_card
          }
        );
        this.result_assistant_teacher_reports =
          result_assistant_teacher_report.data;
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "insert";
        this.data_syslog.page_log = "assistant_teacher_report";
        this.data_syslog.table_log = "assistant_teacher_report";
        this.data_syslog.detail_log = this.assistant_teacher_report_s.assistant_teacher_re_mt_id_card;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
        this.assistant_teachers = result_assistant.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_temporaryQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.assistant_teacherConfirmdialog = false;
    },
    /*    async personnel_temporaryEdit(mt_id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: mt_id_card
      });
      this.editpersonnel_temporary = result.data;
      this.editpersonnel_temporary.p_word = "";
      this.editpersonnel_temporarydialog = true;
    }, */
    async editpersonnel_temporarySubmit() {
      if (this.$refs.editpersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;
        this.editpersonnel_temporary.brith_day = this.brith_day;
        this.editpersonnel_temporary.brith_month = this.brith_month;
        this.editpersonnel_temporary.brith_year = this.brith_year;
        this.editpersonnel_temporary.appoin_day = this.appoin_day;
        this.editpersonnel_temporary.appoin_month = this.appoin_month;
        this.editpersonnel_temporary.appoin_year = this.appoin_year;
        this.editpersonnel_temporary.retrire_year = this.retrire_year;
        let result = await this.$http.post(
          "personnel_temporary.update.admin.php",
          this.editpersonnel_temporary
        );
        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "assistant_teacher";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.editpersonnel_temporary.mt_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_temporarydialog = false;
      }
    },
    async cancel_assistant_teacher_reportdialogSubmit() {
      this.cancel_assistant_teacher_reports.ApiKey = this.ApiKey;
      this.cancel_assistant_teacher_reports.assistant_teacher_re_mt_id_card = this.personnel_temporary_mt_id_card.mt_id_card;
      this.cancel_assistant_teachers.ApiKey = this.ApiKey;
      this.cancel_assistant_teachers.assistant_teacher_mt_id_card = this.personnel_temporary_mt_id_card.mt_id_card;
      this.cancel_assistant_teachers.assistant_teacher_status = "";

      let result = await this.$http.post(
        "assistant_teacher.update.confirm.php",
        this.cancel_assistant_teachers
      );
      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.delete.php",
        this.cancel_assistant_teacher_reports
      );
      if (
        result.data.status == true &&
        result_assistant_teacher_report.data.status == true
      ) {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          mt_id_card: this.personnel_temporary_mt_id_card.mt_id_card
        });
        this.personnel_temporary_mt_id_card = result.data;
        let result_assistant = await this.$http.post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_mt_id_card: this.personnel_temporary_mt_id_card
            .mt_id_card
        });
        let result_assistant_teacher_report = await this.$http.post(
          "assistant_teacher_report.php",
          {
            ApiKey: this.ApiKey,
            assistant_teacher_re_mt_id_card: this.personnel_temporary_mt_id_card
              .mt_id_card
          }
        );
        this.result_assistant_teacher_reports =
          result_assistant_teacher_report.data;
        this.assistant_teachers = result_assistant.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "Cancel";
        this.data_syslog.page_log = "assistant_teacher";
        this.data_syslog.table_log = "assistant_teacher_report";
        this.data_syslog.detail_log = this.cancel_assistant_teachers.assistant_teacher_mt_id_card;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
        this.personnel_temporaryQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.cancel_assistant_teacher_reportdialog = false;
    },

    row_classes(item) {
   /*     if (item.assistant_teacher_comment == "collegeCC") {
        return "lime";
      } */
    }
  },
  computed: {
    appoin_day() {
      return this.$route.query.appoin_day;
    },
    appoin_month() {
      return this.$route.query.appoin_month;
    },
    appoin_year() {
      return this.$route.query.appoin_year;
    },

    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    times_s() {
      let times = this.personnel_temporary_mt_id_card.last_time;
      let result;
      if (times == "1") {
        result = 2;
      } else if (times == "2") {
        result = 3;
      } else if (times == "3") {
        result = 4;
      } else if (times == "4") {
        result = "ครบการประเมิน";
      } else {
        result = 1;
      }
      return result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    color() {
      return "indigo darken-4";
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { Assistant_teacher_bar }
};
</script>

<style>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>
